import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import Heading from "../components/Heading";
import Content, { HTMLContent } from '../components/Content'

export const TrainingPageTemplate = ({
  image,
  title,
  subtitle,
  intro,
  testimonials,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content

  return (
      <div className="content">
        <Heading title={title} image={image} subtitle={subtitle}/>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-7 is-offset-1">
                  <h3 className="has-text-weight-semibold is-size-2">{intro.heading}</h3>
                  <p>{intro.description}</p>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <Features gridItems={intro.blurbs} />
                  <Testimonials testimonials={testimonials} />
                </div>
              </div>
              <div className="column is-10 is-offset-1">
                <PostContent content={content}/>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

TrainingPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  intro: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
}

const TrainingPage = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TrainingPageTemplate
        content={html}
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        intro={frontmatter.intro}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

TrainingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default TrainingPage

export const trainingPageQuery = graphql`
  query TrainingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`
